import { render, staticRenderFns } from "./user-archives.vue?vue&type=template&id=69bb06c2&scoped=true&"
import script from "./user-archives.vue?vue&type=script&lang=ts&"
export * from "./user-archives.vue?vue&type=script&lang=ts&"
import style0 from "./user-archives.vue?vue&type=style&index=0&id=69bb06c2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69bb06c2",
  null
  
)

export default component.exports