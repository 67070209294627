<template>
	<a-config-provider :locale="zh_CN">
		<a-spin :spinning="spinning">
			<!-- 标题 -->
			<div class="modal-header">
				<div class="modal-title">
					<a-icon type="medicine-box" />
					<span v-if="id">{{ l('编辑账号') }}</span>
					<span v-else>{{ l('新增账号') }}</span>
				</div>
			</div>
			<div>
				<a-tabs default-active-key="1">
					<!--                    账号信息-->
					<a-tab-pane key="1">
						<span slot="tab">
							<a-icon type="user" />
							{{ l('UserInformations') }}
						</span>
						<div class="my-md">
							<a-row>
								<a-col :span="12">
									<a-row>
										<a-col :span="6">
											<q-label required :label="l('账号')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input placeholder="请输入账号" v-model="userEntity.userName" />
										</a-col>
									</a-row>
									<a-row>
										<a-col :span="6">
											<q-label :label="l('姓名')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input placeholder="请输入姓名" v-model="userEntity.realName" />
										</a-col>
									</a-row>
									<a-row>
										<a-col :span="6">
											<q-label required :label="l('密码')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input-password placeholder="请输入密码" v-model="userEntity.password" />
										</a-col>
									</a-row>
								</a-col>
								<a-col :span="12">
									<a-row>
										<a-col :span="10" offset="10">
											<a-upload
												name="profilePictureFile"
												:action="uploadPictureUrl"
												listType="picture-card"
												:fileList="fileList"
												:beforeUpload="beforeUpload"
												accept="image/*"
												:headers="uploadHeaders"
												@preview="handlePreview"
												@change="uploadPictureChange($event)"
											>
												<div v-if="fileList.length < 1">
													<a-icon type="plus" />
													<div class="ant-upload-text">{{ l('UploadProfilePicture') }}</div>
												</div>
											</a-upload>
											<a-modal
												:visible="previewVisible"
												:footer="null"
												@cancel="previewVisible = false"
											>
												<img alt="example" style="width: 100%" :src="previewImage" />
											</a-modal>
										</a-col>
									</a-row>

								</a-col>
							</a-row>

							<a-row>
								<a-col :span="12">
									<a-row>
										<a-col :span="6">
											<q-label required :label="l('密码核对')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input-password placeholder="请输入密码" v-model="passwordRepeat" />
										</a-col>
									</a-row>

									<a-row>
										<a-col :span="6">
											<q-label :label="l('手机号')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input placeholder="请输入手机号" v-model="userEntity.phoneNumber" />
										</a-col>
									</a-row>


								</a-col>
								<a-col :span="12">

									<a-row>
										<a-col :span="6">
											<q-label :label="l('公司邮箱')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input placeholder="请输入公司邮箱" v-model="userEntity.emailAddress" />
										</a-col>
									</a-row>

									<a-row>
										<a-col :span="6">
											<q-label :label="l('员工卡')"> </q-label>
										</a-col>
										<a-col :span="18">
											<a-input placeholder="请输入员工卡卡号" v-model="userEntity.rfid" />
										</a-col>
									</a-row>
								</a-col>
							</a-row>
							<a-row>
								<a-col :span="12">
									<a-col :span="6">
										<q-label :label="l('推送设置')"> </q-label>
									</a-col>
									<a-col :span="18">
										<a-checkbox v-model="userEntity.enableEmail">
										    {{l('邮箱')}}
										</a-checkbox>
										<a-checkbox v-model="userEntity.enableAPPPush">
										    {{l('APP推送')}}
										</a-checkbox>
									</a-col>
								</a-col>
								<a-col :span="12">
									<a-col :span="6">
										<q-label :label="l('登录选项')"> </q-label>
									</a-col>
									<a-col :span="18">
										<div style="margin-top: 3px;">
											<a-switch style="margin-top: -3px;"
												v-model="userEntity.isOnlyLdapLogin"
												:checkedChildren="l('Yes')"
												:unCheckedChildren="l('No')"
											/>
											<span>
												{{l('仅限域账号')}}
											</span>
										</div>
									</a-col>
								</a-col>
							</a-row>
							<a-row>
								<a-col :span="3">
									<q-label :label="l('备注')"> </q-label>
								</a-col>
								<a-col :span="21">
									<a-textarea placeholder="请输入备注" allow-clear v-model="userEntity.remarks" />
								</a-col>
							</a-row>
							<a-row>
								<a-col :span="3">
									<q-label :label="l('启用锁定')"> </q-label>
								</a-col>
								<a-col :span="21">
									<a-switch
										v-model="userEntity.isLockoutEnabled"
										:checkedChildren="l('Yes')"
										:unCheckedChildren="l('No')"
									/>
								</a-col>
							</a-row>
						</div>
					</a-tab-pane>
					<!--                    角色信息-->
					<a-tab-pane key="2">
						<a-badge slot="tab" :count="checkedRoles.length">
							<a-icon type="medicine-box" />
							{{ l('Roles') }}
						</a-badge>
						<a-checkbox-group @change="roleonChange" :value="CheckRoles">
							<a-checkbox
								v-for="(item, key) in role"
								:value="item.roleDisplayName"
								:checked="true"
								:key="key"
							>
								{{ item.roleDisplayName }}
							</a-checkbox>
						</a-checkbox-group>
					</a-tab-pane>
				</a-tabs>
			</div>
			<!-- 按钮 -->
			<div class="modal-footer">
				<a-button @click="close" type="button">
					<a-icon type="close-circle" />
					{{ l('Cancel') }}
				</a-button>
				<a-button :type="'primary'" @click="save">
					<a-icon :type="saving ? 'loading' : 'save'" />
					{{ l('Save') }}
				</a-button>
			</div>
		</a-spin>
	</a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import ModalComponentBase from '../../../../shared/component-base/modal-component-base';
import { CreateOrUpdateUserInput, UserEditDto, UserServiceProxy } from '../../../../shared/service-proxies';
import { AppConsts } from '../../../../abpPro/AppConsts';
import ImageUtils from '../../../../shared/utils/image-utils';

export default {
	name: 'create-or-edit-login-user',
	mixins: [ModalComponentBase],
	data() {
		return {
			zh_CN,
			spinning: false,
			id: undefined,
			// 角色list
			roleList: [],
			checkedRoles: [],
			//角色
			role: [],
			CheckRoles: [],
			userEntity: new UserEditDto(),
			userPicturePath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
			// 上传图片
			fileList: [],
			uploadPictureUrl: AppConsts.remoteServiceBaseUrl + '/Profile/UploadProfilePictureReturnFileId',
			maxProfilPictureBytesValue: AppConsts.maxProfilPictureMb,
			uploadHeaders: {
				Authorization: 'Bearer ' + abp.auth.getToken(),
				type: 'head',
			},
			previewVisible: false,
			previewImage: '',
			//核对密码
			passwordRepeat: undefined,
		};
	},
	created() {
		this.fullData();
		this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
	},
	mounted() {
		console.log("123")
		this.init();
	},
	methods: {
		/**
		 * 初始化
		 */
		init() {
			this.spinning = true;
			this.CheckRoles = [];
			this._userServiceProxy
				.getForEditTree(this.id)
				.finally(() => (this.spinning = false))
				.then((result) => {
					// this.roleList = result.roles.map(
					//     item => item.roleDisplayName
					// );

					console.log(result)

					this.role = result.roles;
					this.role.forEach((item) => {
						if (item.isAssigned) {
							this.CheckRoles.push(item.roleDisplayName);
						}
					});
					this.userEntity = result.user;
					if(this.id == null) {
						this.userEntity.enableAPPPush = true;
						this.userEntity.enableEmail = true;
					}
					this.userName = result.user.userName;
					// 设置头像
					if (result.user.profilePictureId && result.user.userPictureType) {
						this.fileList = [
							{
								uid: -1,
								name: result.user.profilePictureId,
								status: 'done',
								url: this.userPicturePath + result.user.profilePictureId + result.user.userPictureType,
							},
						];
						// this.getProfilePicture(result.user.profilePictureId);
					}
				});
		},
		/**
		 * 上传图片
		 */
		beforeUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
			if (!isJPG) {
				abp.message.error(this.l('OnlySupportPictureFile'));
			}
			const isLtXM = file.size / 1024 / 1024 < this.maxProfilPictureBytesValue;
			if (!isLtXM) {
				abp.message.error(this.l('ProfilePicture_Warn_SizeLimit', this.maxProfilPictureBytesValue));
			}
			return isJPG && isLtXM;
		},
		async handlePreview(file) {
			if (!file.url && !file.preview) {
				file.preview = await ImageUtils.getBase64(file.originFileObj);
			}
			this.previewImage = file.url || file.preview;
			this.previewVisible = true;
		},
		uploadPictureChange({ file, fileList }) {
			if (file.status === 'done') {
				this.userEntity.userPictureType = file.response.result.fileType;
				this.userEntity.profilePictureId = file.response.result.profilePictureId;
			}
			this.fileList = fileList;
		},
		roleonChange(enent) {
			this.CheckRoles = enent;
		},
		save() {
			if (!this.id) {
				if (!this.userEntity.userName) {
					return abp.message.warn('请输入账号');
				}
				if (!this.userEntity.password) {
					return abp.message.warn('请输入密码');
				}
				if (!this.passwordRepeat) {
					return abp.message.warn('请输入核对密码');
				}
				if (this.userEntity.password !== this.passwordRepeat) {
					return abp.message.warn('两次密码输入的不一致');
				}
			} else {
				if (this.userEntity.password || this.passwordRepeat) {
					if (this.userEntity.password !== this.passwordRepeat) {
						return abp.message.warn('两次密码输入的不一致');
					}
				}
			}
			this.saving = true;
			this.spinning = true;
			let input = new CreateOrUpdateUserInput();
			input.user = this.userEntity;
			input.assignedRoleNames = this.CheckRoles;
			this._userServiceProxy
				.createOrUpdate(input)
				.finally(() => {
					this.saving = false;
					this.spinning = false;
				})
				.then((res) => {
					this.$notification['success']({
						message: this.l('SavedSuccessfully'),
					});
					this.success(true);
				});
		},
	},
};
</script>

<style scoped></style>
