<template>
    <!-- usePagination开启分页 -->
    <!-- loading绑定引入页面的蒙层加载变量 -->
    <q-container usePagination :loading="loading">
        <!-- 使用具名插槽 #toolbar -->
        <!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
        <template #toolbar>
            <a-row>
                <a-col :span="16">
                    <a-button :type="'primary'" @click="Create()" v-if="isGranted('login_user_add')">
                        <a-icon type="plus" />
                        <span>{{ l('Create') }}</span>
                    </a-button>
                    <a-button :type="'primary'" @click="Edit()" v-if="isGranted('login_user_edit')">
                        <a-icon type="edit" />
                        <span>{{ l('Edit') }}</span>
                    </a-button>

                    <a-button type="primary" @click="Import" v-if="isGranted('login_user_import')">
                        <a-icon type="import" />
                        {{l('import')}}
                    </a-button>

                    <a-dropdown :placement="'bottomRight'" v-if="isdropdown">
                        <a-button @click="(e) => e.preventDefault()">
                            {{ l('Action') }}
                            <a-icon type="down" />
                        </a-button>
                        <a-menu slot="overlay">
                            <!-- 使用此用户登录 -->
                            <a-menu-item @click="tenantImpersonateLogin()"
                                v-if="isGranted('login_user_login_thisuser')">
                                <span>
                                    <a-icon type="login" />
                                    <span>{{ l('LoginAsThisUser') }}</span>
                                </span>
                            </a-menu-item>
                            <!-- 功能授权 -->
                            <a-menu-item @click="userAuthorize()" v-if="isGranted('login_user_authorize')">
                                <span>
                                    <a-icon type="codepen" />
                                    <span>{{ l('功能授权') }}</span>
                                </span>
                            </a-menu-item>
                            <!-- 数据授权 -->
                            <!-- <a-menu-item @click="interfaceAuthorize()" v-if="isGranted('interface_authorize')">
								<span>
									<a-icon type="codepen" />
									<span>{{ l('数据授权') }}</span>
								</span>
							</a-menu-item> -->
                            <!-- 限制终端 -->
                            <a-menu-item @click="restrictClient()" v-if="isGranted('restrict_client_anthorize')">
                                <span>
                                    <a-icon type="codepen" />
                                    <span>{{ l('限制终端') }}</span>
                                </span>
                            </a-menu-item>
                            <!-- 清空缓存 -->
                            <a-menu-item @click="emptyUserToken()"
                                v-if="isTenantAdmin == 1 || isGranted('login_user_empty_token')">
                                <span>
                                    <a-icon type="codepen" />
                                    <span>{{ l('清空缓存') }}</span>
                                </span>
                            </a-menu-item>
                            <!-- 修改 -->
                            <!-- <a-menu-item @click="Edit()" v-if="isGranted('login_user_edit')">
								<span>
									<a-icon type="edit" />
									<span>{{ l('Edit') }}</span>
								</span>
							</a-menu-item> -->
                            <!-- 解锁 -->
                            <a-menu-item @click="unlockTenantAdminUser()" v-if="isGranted('login_user_unlock')">
                                <span>
                                    <a-icon type="unlock" />
                                    <span>{{ l('Unlock') + '/' + l('UserLockOut')  }}</span>
                                </span>
                            </a-menu-item>
                            <!-- 删除 -->
                            <!-- <a-menu-item @click="BatchDelete()" style="color: red;"
                                v-if="isGranted('login_user_delete')">
                                <a-icon type="delete" />
                                <span>{{ l('Delete') }}</span>
                            </a-menu-item> -->
                        </a-menu>
                    </a-dropdown>

                    <a-checkbox v-model="islock" @change="getData()" style="margin-left: 10px;">
                        {{l("UserLockOut")}}
                      </a-checkbox>

                </a-col>
                <a-col :span="6" offset="2">
                    <a-input-search name="filterText" :placeholder="l('SearchWithThreeDot')" @search="search"
                        enterButton v-model="filterText" />
                </a-col>
            </a-row>
        </template>
        <!-- 使用具名插槽slot="table" -->
        <!-- 插槽作用域的变量 slot-scope="scopeParam" -->
        <!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

        <!-- **************行选择*************** -->
        <!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
        <!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
        <template slot="table" slot-scope="scopeParam">
            <a-table ref="table" size="small" :pagination="false" :columns="columns"
                :rowKey="(tableDatas) => tableDatas.id" :scroll="{ y: scopeParam.scroll.y }" :dataSource="tableData"
                :customRow="customRow" :rowClassName="rowClassName">
                <span slot="UserImg" slot-scope="text, record">
                    <a-avatar shape="square" size="large" icon="user"
                        :src="imgPath + record.profilePictureId + record.userPictureType"
                        v-if="record.profilePictureId && record.userPictureType" />
                    <a-avatar shape="square" size="large" style="backgroundColor:rgb(0, 162, 174)" v-else>
                        {{ record.realName ? record.realName : record.userName }}
                    </a-avatar>
                </span>
                <span slot="enableAPPPush" slot-scope="text,record">
                    <a-icon type="check" style="color: green" v-if="text == true" />
                    <a-icon type="close" style="color: red" v-else />
                </span>
                <span slot="enableEmail" slot-scope="text,record">
                    <a-icon type="check" style="color: green" v-if="text == true" />
                    <a-icon type="close" style="color: red" v-else />
                </span>
                <span slot="roles" slot-scope="text, record">
                    <a-tag color="#2db7f5" v-for="(item, index) in record.roles" :key="index">
                        {{ item.roleName }}
                    </a-tag>
                </span>
                <span slot-scope="text, record" slot="isLockoutEnabled">
                    <a-icon type="lock" v-if="record.isLockoutEnabled" />
                </span>
            </a-table>
        </template>
        <!-- 使用具名插槽 #page -->
        <template #page>
            <!-- 分页组件只需复制以下代码 -->
            <!-- 处理逻辑和变量都在 app-component-base混入文件 -->
            <a-pagination v-model="pageNumber" :total="totalItems" showSizeChanger size="small"
                :defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
                :showTotal="() => showTotal" @change="onPageChange" @showSizeChange="showPageSizeChange" />
        </template>
    </q-container>
</template>

<script>
    import { AppComponentBase } from '../../../shared/component-base';
    import { UserServiceProxy } from '../../../shared/service-proxies';
    import { AppConsts } from '../../../abpPro/AppConsts';
    import * as _ from 'lodash';
    import ModalHelper from '../../../shared/helpers/modal/modal-helper';
    import CreateOrEditLoginUser from './create-or-edit-login-user/create-or-edit-login-user';
    import Authorize from '../../admin/roles/authorize/authorize';
    import EditUserPermissionsComponent from '../../admin/users/edit-user-permissions/edit-user-permissions';
    import { impersonationService } from '../../../shared/auth';
    import { appSessionService } from '@/shared/abp';
    import { abpService } from '../../../shared/abp';
    import InterfaceAuthorize from '../../../app/admin/roles/interface-authorize/interface-authorize';
    import { TokenAuthServiceProxy, AccountsApiServiceProxy, AccountServiceProxy } from '@/shared/service-proxies';
    import RestrictClient from '../../../app/admin/roles/restrict-client/restrict-client';
    import ImportExcelModel from './import-excel-model/import-excel-model';

    export default {
        name: 'login-user',
        mixins: [AppComponentBase],
        components: { CreateOrEditLoginUser, InterfaceAuthorize, RestrictClient },
        data() {
            return {
                WorkTypeList: [],

                columns: [
                    {
                        title: this.l('头像'),
                        dataIndex: 'UserImg',
                        sorter: false,
                        align: 'center',
                        scopedSlots: { customRender: 'UserImg' },
                    },
                    {
                        title: this.l('账号'),
                        dataIndex: 'userName',
                        sorter: false,
                        align: 'center',
                        ellipsis: true,
                        scopedSlots: { customRender: 'userName' },
                    },
                    {
                        title: this.l('显示名称'),
                        dataIndex: 'realName',
                        sorter: false,
                        align: 'center',
                        ellipsis: true,
                        scopedSlots: { customRender: 'realName' },
                    }, {
                        title: this.l('邮箱'),
                        dataIndex: 'emailAddress',
                        sorter: false,
                        align: 'center',
                        ellipsis: true,
                    },
                    {
                        title: this.l('角色'),
                        dataIndex: 'roles',
                        sorter: false,
                        align: 'center',
                        scopedSlots: { customRender: 'roles' },
                    },
                    {
                        title: this.l('APP推送'),
                        dataIndex: 'enableAPPPush',
                        sorter: false,
                        align: 'center',
                        ellipsis: true,
                        scopedSlots: { customRender: 'enableAPPPush' },
                    },
                    {
                        title: this.l('邮箱推送'),
                        dataIndex: 'enableEmail',
                        sorter: false,
                        align: 'center',
                        ellipsis: true,
                        scopedSlots: { customRender: 'enableEmail' },
                    },
                    {
                        title: this.l('备注'),
                        dataIndex: 'remarks',
                        align: 'center',
                        ellipsis: true,
                        scopedSlots: { customRender: 'remarks' },
                    },
                    {
                        title: this.l('锁定'),
                        dataIndex: 'isLockoutEnabled',
                        align: 'center',
                        scopedSlots: { customRender: 'isLockoutEnabled' },
                    },
                ],
                tableData: [],
                imgPath: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/${abp.session.tenantId}/`,
                islock:false,
            };
        },
        computed: {
            isTenantAdmin() {
                return abpService.abp.userInfo.isTenantAdmin;
            },
            isdropdown() {
                return (
                    this.isGranted('login_user_login_thisuser') ||
                    this.isGranted('login_user_authorize') ||
                    this.isGranted('login_user_empty_token') ||
                    this.isGranted('login_user_edit') ||
                    this.isGranted('login_user_unlock') ||
                    this.isGranted('login_user_delete')
                );
            },
        },
        created() {
            this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
            this._appSessionService = appSessionService;
            this.tokenAuthService = new TokenAuthServiceProxy(this.$apiUrl, this.$api);
            this.accountsApiService = new AccountsApiServiceProxy(this.$apiUrl, this.$api);
            this.accountService = new AccountServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
            this.search();
        },
        methods: {

            //导入
            Import() {
                ModalHelper.create(
                    ImportExcelModel,
                    {
                    },
                    {
                        width: "800px"
                    }
                ).subscribe(res => {
                    this.getData();
                });
            },

            getData() {
                this.loading = true;
                this._userServiceProxy
                    .getLoginUserPage(
                        this.islock,
                        this.filterText,
                        this.request.sorting,
                        this.request.maxResultCount,
                        this.request.skipCount
                    )
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.tableData = res.items;
                        this.tableData.map((item) => {
                            item.checked = false;
                        });
                        this.totalItems = res.totalCount;
                        this.totalPages = Math.ceil(res.totalCount / this.request.maxResultCount);
                        this.pagerange = [
                            (this.pageNumber - 1) * this.request.maxResultCount + 1,
                            this.pageNumber * this.request.maxResultCount,
                        ];
                    });
            },

            /**
             * 添加
             */
            Create() {
                ModalHelper.create(
                    CreateOrEditLoginUser,
                    {},
                    {
                        width: '800px',
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.search();
                    }
                });
            },
            /**
             * 编辑
             * @returns {any}
             * @constructor
             */
            Edit() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                if (this.selectedRows.length > 1) {
                    return abp.message.warn('只能选择一项进行操作');
                }
                ModalHelper.create(
                    CreateOrEditLoginUser,
                    { id: this.selectedRows[0].id },
                    {
                        width: '800px',
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.search();
                    }
                });
            },
            /**
             * 批量删除
             * @constructor
             */
            BatchDelete() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                this.$confirm({
                    title: '确认操作',
                    content: '你确认删除这' + this.selectedRows.length + '项吗？',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        let ids = _.map(this.selectedRows, 'id');
                        this.loading = true;
                        this._userServiceProxy
                            .batchDelete(ids)
                            .finally(() => {
                                this.loading = false;
                            })
                            .then((res) => {
                                this.search();
                                this.$notification['success']({
                                    message: this.l('SuccessfullyDeleted'),
                                });
                            });
                    },
                });
            },
            /**
             * 解锁
             */
            unlockTenantAdminUser() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                if (this.selectedRows.length > 1) {
                    return abp.message.warn('只能选择一项进行操作');
                }
                this.loading = true;
                this._userServiceProxy
                    .unlock({
                        id: this.selectedRows[0].id,
                    })
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(() => {
                        this.search();
                        this.$notification['success']({
                            message: this.l('解锁成功'),
                        });
                    });
            },
            /**
             * 功能授权 （type:1角色，2用户，3部门）
             * @param id
             */
            userAuthorize() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                if (this.selectedRows.length > 1) {
                    return abp.message.warn('只能选择一项进行操作');
                }
                ModalHelper.create(
                    Authorize,
                    {
                        Id: this.selectedRows[0].id,
                        type: 2,
                        headerTitle: this.selectedRows[0].realName
                            ? this.selectedRows[0].realName
                            : this.selectedRows[0].userName,
                    },
                    {
                        width: '600px',
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.search();
                    }
                });
            },
            interfaceAuthorize() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                if (this.selectedRows.length > 1) {
                    return abp.message.warn('只能选择一项进行操作');
                }
                console.log(this.selectedRows);
                ModalHelper.create(
                    InterfaceAuthorize,
                    {
                        type: 2,
                        ObjectId: this.selectedRows[0].id,
                        headertitle: this.selectedRows[0].realName,
                    },
                    { width: '1000px' }
                ).subscribe((res) => { });
            },
            restrictClient(record) {
                ModalHelper.create(
                    RestrictClient,
                    {
                        type: 2,
                        ObjectId: this.selectedRows[0].id,
                        headertitle: this.selectedRows[0].realName,
                    },
                    { width: '500px' }
                ).subscribe((res) => { });
            },
            /**
             * 权限
             */
            editUserPermissions() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                if (this.selectedRows.length > 1) {
                    return abp.message.warn('只能选择一项进行操作');
                }
                ModalHelper.create(
                    EditUserPermissionsComponent,
                    { id: this.selectedRows[0].id, userName: this.selectedRows[0].userName },
                    {
                        width: '900px',
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.search();
                    }
                });
            },
            /**
             * 使用此账户登录
             */
            tenantImpersonateLogin() {
                if (this.selectedRows.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                if (this.selectedRows.length > 1) {
                    return abp.message.warn('只能选择一项进行操作');
                }

                impersonationService.impersonate(this.selectedRows[0].id, this._appSessionService.tenantId);
            },
            emptyUserToken() {
                if (this.selectedRowKeys.length <= 0) {
                    return abp.message.warn('请至少选择一项进行操作');
                }
                this.$confirm({
                    title: '确认操作',
                    content: '你确认要清除这 ' + this.selectedRowKeys.length + ' 项账号的缓存数据吗?',
                    okText: '确认',
                    cancelText: '取消',
                    onOk: () => {
                        this.loading = true;
                        this._userServiceProxy
                            .emptyUserToken(this.selectedRowKeys)
                            .finally(() => {
                                this.loading = false;
                            })
                            .then((res) => {
                                this.search();
                                this.$notification['success']({
                                    message: this.l('清除成功'),
                                });
                            });
                    },
                });
            },
        },
    };
</script>

<style scoped></style>