<template>
    <!-- usePagination开启分页 -->
    <!-- loading绑定引入页面的蒙层加载变量 -->
    <q-container usePagination :loading="loading">
        <!-- 使用具名插槽 #toolbar -->
        <!-- #toolbar可替换成slot="toolbar" #井号只是语法糖 -->
        <template #toolbar>
            <a-row :gutter="8">
                <a-col :span="10">
                    <a-button :type="'primary'" v-if="isGranted('roles_add')" @click="createOrEdit()">
                        <a-icon type="plus" />
                        <span>{{ l('CreateNewRole') }}</span>
                    </a-button>
                    <a-button :type="'danger'" v-if="isGranted('roles_delete')" @click="batchDelete">
                        <a-icon type="delete" />
                        <span>{{ l('BatchDelete') }}</span>
                    </a-button>
                    <a-button :type="'primary'" v-if="isGranted('roles_anthorize')"
                        @click="onauthorize(false)">网页功能授权</a-button>
                    <a-button :type="'primary'" v-if="isGranted('approles_anthorize')"
                        @click="onauthorize(true)">APP功能授权</a-button>
                </a-col>
                <!-- 搜索 -->
                <a-col :span="6" :offset="8">
                    <a-input-search style="width: 200px" name="filterText" :placeholder="l('SearchWithThreeDot')"
                        @search="search" enterButton v-model="filterText" v-decorator="['filterText']" />
                </a-col>
            </a-row>
        </template>
        <!-- 使用具名插槽slot="table" -->
        <!-- 插槽作用域的变量 slot-scope="scopeParam" -->
        <!-- 通过使用scopeParam.scroll.y 获取组件内部计算y值 -->

        <!-- **************行选择*************** -->
        <!-- customRow需要选择表格时添加属性和同名方法，方法不需要重新，在混入里面 -->
        <!-- 行选择默认单选，多选需要再data重写变量 isMultiple 并赋值true -->
        <template slot="table" slot-scope="scopeParam">
            <a-table ref="table" size="small" :pagination="false" :columns="columns"
                :rowKey="(tableDatas) => tableDatas.id" :scroll="{ y: scopeParam.scroll.y }" :dataSource="tableData"
                :customRow="customRow" :rowClassName="rowClassName">
                <div class="displayName" slot="displayName" slot-scope="text, record">
                    {{ record.displayName }}
                    <a-tag v-if="record.isStatic" color="#108ee9"> {{ l('Static') }}</a-tag>
                    <a-tag v-if="record.isDefault" color="#2db7f5"> {{ l('Default') }}</a-tag>
                </div>
                <span slot="actions" slot-scope="text, record">
                    <!--                            查看用户-->
                    <a @click="userView(record.id, record.name)" v-if="isGranted('roles_view_user')">
                        {{ l('查看用户') }}</a>
                    <!-- <a-divider type="vertical" /> -->
                    <!-- 功能授权 -->
                    <!-- <a @click="onauthorize(record)" v-if="isGranted('roles_anthorize')"> {{ l('功能授权') }}</a> -->
                    <!-- <a-divider type="vertical" />
                    <a @click="interfaceAuthorize(record)" v-if="isGranted('interface_anthorize')">
                        {{ l('数据授权') }}</a> -->
                    <a-divider type="vertical" />
                    <a @click="restrictClient(record)" v-if="isGranted('restrict_client_anthorize')">
                        {{ l('限制终端') }}</a>
                    <a-divider type="vertical" />
                    <!-- 删除 -->
                    <!-- 修改 -->
                    <a class="table-edit" @click="createOrEdit(record.id)" v-if="isGranted('roles_edit')">
                        <a-icon type="edit" />{{ l('Edit') }}</a>
                    <a-divider type="vertical" v-if="!record.isStatic && isGranted('roles_delete')" />
                    <!-- 删除 -->
                    <a-popconfirm placement="top" v-if="!record.isStatic && isGranted('roles_delete')" :okText="l('Ok')"
                        :cancelText="l('Cancel')" @confirm="deleteItem(record)">
                        <template slot="title">
                            {{ l('ConfirmDeleteWarningMessage') }}
                        </template>
                        <a class="table-delete"> <a-icon type="delete" />{{ l('Delete') }}</a>
                    </a-popconfirm>
                </span>
            </a-table>
        </template>
        <!-- 使用具名插槽 #page -->
        <template #page>
            <!-- 分页组件只需复制以下代码 -->
            <!-- 处理逻辑和变量都在 app-component-base混入文件 -->
            <a-pagination v-model="pageNumber" :total="totalItems" showSizeChanger size="small"
                :defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
                :showTotal="() => showTotal" @change="onPageChange" @showSizeChange="showPageSizeChange" />
        </template>
    </q-container>
</template>

<script>
    import { AppComponentBase } from '@/shared/component-base';
    import PermissionCombox from '../shared/permission-combox/permission-combox.vue';
    import { ModalHelper } from '@/shared/helpers';
    import { RoleServiceProxy } from '@/shared/service-proxies';
    import CreateEditRoleCompent from './create-or-edit-role/create-or-edit-role';
    import moment from 'moment';
    import Authorize from './authorize/authorize';
    import * as _ from 'lodash';
    import { AppConsts } from '../../../abpPro/AppConsts';
    // import UserRoleView from './user-role-view/user-role-view';
    import InterfaceAuthorize from './interface-authorize/interface-authorize';
    import RestrictClient from './restrict-client/restrict-client';
    import viewRole from "@/app/admin/roles/view-or-add-role/view-role";

    export default {
        mixins: [AppComponentBase],
        name: 'tenants',
        components: {
            PermissionCombox,
            CreateEditRoleCompent,
            Authorize,
            viewRole,
            InterfaceAuthorize,
            RestrictClient,
        },
        data() {
            return {
                _roleServiceProxy: null,

                // 选中的权限过滤
                selectedPermission: [],
                // 表格
                columns: [
                    {
                        title: this.l('RoleName'),
                        dataIndex: 'displayName',
                        align: 'center',
                        scopedSlots: {
                            customRender: 'displayName',
                        },
                    },
                    {
                        title: this.l('CreationTime'),
                        dataIndex: 'creationTimeStr',
                        align: 'center',
                        scopedSlots: {
                            customRender: 'creationTimeStr',
                        },
                    },
                    {
                        title: this.l('Actions'),
                        dataIndex: 'actions',
                        align: 'center',
                        width: 500,
                        scopedSlots: {
                            customRender: 'actions',
                        },
                    },
                ],

                tableData: [],
            };
        },
        created() {
            this._roleServiceProxy = new RoleServiceProxy(this.$apiUrl, this.$api);
            this.getData();
        },
        methods: {
            userView(id, name) {
                ModalHelper.create(
                    viewRole,
                    {
                        roleId: id,
                        title: name,
                    },
                    {
                        width: '870px',
                    }
                ).subscribe((res) => { });
            },
            /**
             * 获取数据
             */
            getData() {
                this.loading = true;
                this._roleServiceProxy
                    .getPaged(
                        this.selectedPermission,
                        this.filterText,
                        this.request.sorting,
                        this.request.maxResultCount,
                        this.request.skipCount
                    )
                    .finally(() => {
                        this.loading = false;
                    })
                    .then((res) => {
                        this.tableData = res.items;
                        this.tableData.map((item) => {
                            item.checked = false;
                            item.creationTimeStr = item.creationTime
                                ? moment(item.creationTime).format('YYYY-MM-DD HH:mm:ss')
                                : '-';
                        });
                        this.totalItems = res.totalCount;
                    });
            },

            /**
             * 添加角色  修改角色
             */
            createOrEdit(id) {
                ModalHelper.create(
                    CreateEditRoleCompent,
                    {
                        id: id,
                    },
                    {
                        width: '900px',
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.refresh();
                    }
                });
            },
            /**
             * 批量删除
             */
            batchDelete(e) {
                const selectCount = this.selectedRowKeys.length;
                if (selectCount <= 0) {
                    abp.message.warn(this.l('PleaseSelectAtLeastOneItem'));
                    return;
                }
                this.message.confirm(this.l('ConfirmDeleteXItemsWarningMessage', selectCount), (res) => {
                    if (res) {
                        const ids = _.map(this.selectedRowKeys);
                        this.loading = true;
                        this._roleServiceProxy
                            .batchDelete(ids)
                            .finally(() => {
                                this.loading = false;
                            })
                            .then(() => {
                                this.refresh();
                                this.$notification['success']({
                                    message: this.l('SuccessfullyDeleted'),
                                });
                            });
                    }
                });
            },
            interfaceAuthorize(record) {
                ModalHelper.create(
                    InterfaceAuthorize,
                    {
                        type: 1,
                        ObjectId: record.id,
                        headertitle: record.displayName,
                    },
                    { width: '1000px' }
                ).subscribe((res) => { });
            },

            restrictClient(record) {
                ModalHelper.create(
                    RestrictClient,
                    {
                        type: 1,
                        ObjectId: record.id,
                        headertitle: record.displayName,
                    },
                    { width: '500px' }
                ).subscribe((res) => { });
            },

            /**
             * 单个删除
             */
            deleteItem(item) {
                if (item.isStatic) {
                    abp.message.warn(this.l('XUserCannotBeDeleted', AppConsts.userManagement.defaultAdminUserName));
                    return;
                }
                this.loading = true;
                this._roleServiceProxy
                    .delete(item.id)
                    .finally(() => {
                        this.loading = false;
                    })
                    .then(() => {
                        this.refresh();
                        this.$notification['success']({
                            message: this.l('SuccessfullyDeleted'),
                        });
                    });
            },

            /**
             * 功能授权 （type:1角色，2用户，3部门）
             * @param record
             */
            onauthorize(record) {
                if (this.selectedRowKeys.length > 0) {
                    var id = this.selectedRowObj["r" + this.selectedRowKeys[0]].id;
                    var displayName =
                        this.selectedRowObj["r" + this.selectedRowKeys[0]].displayName;
                    ModalHelper.create(
                        Authorize,
                        // {
                        //     Id: record.id,
                        //     type: 1,
                        //     headerTitle: record.displayName,
                        // },
                        {
                            Id: id,
                            type: 1,
                            headerTitle: displayName,
                            IsApp: record
                        },
                        {
                            width: '600px',
                        }
                    ).subscribe((res) => {
                        if (res) {
                            this.refresh();
                        }
                    });
                }
            },
        },
    };
</script>

<style scoped lang="less">
    @import './roles.less';
</style>