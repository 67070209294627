





























































































































































































































































































































































































    import {ModalComponentBase} from "@/shared/component-base";
    import {ProfileServiceProxy, UserArchiverDto, UserListDto, UserServiceProxy} from "@/shared";
    import moment from "moment";
    import {AppConsts} from "@/abpPro/AppConsts";

    export default {
        name: "user-archives",
        mixins: [ModalComponentBase],
        data() {
            return {
                spinning: false,
                isPersonnel: true,
                isContract: true,
                isSocialRelation: true,
                isEducationBackground: true,
                isWorkExperience: true,
                isSkillInfo: true,
                id: undefined,
                UserArchiver: [],
                user: [],
                //合同信息
                personnelContracts: [],
                //社会关系
                socialRelation: [],
                //工作经验
                workExperience: [],
                headPath:`${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/UserPicture/File/${abp.session.tenantId}/`,
                //教育背景
                educationBackground: [],
                //证书信息
                skillInfo: [],
                userImg:undefined
            }
        },
        mounted() {
            this.fullData(); // 模态框必须,填充数据到data字段
            this._userServiceProxy = new UserServiceProxy(this.$apiUrl, this.$api);
            this._profileServiceProxy = new ProfileServiceProxy(this.$apiUrl, this.$api);
            if (this.id) {
                this.initData();
            }

        },
        methods: {
            initData() {
                this.spinning = true;
                this._userServiceProxy.getUserArchiverByUserId(this.id)
                    .finally(() => {
                        this.spinning = false;
                    }).then(res => {
                    this.user = res.user;
                    if(this.user.idPhoto){
                        this.userImg=this.headPath+this.user.idPhoto;
                        // this.getProfilePicture(this.user.profilePictureId);
                    }
                    this.user.hireDate = this.user.hireDate ? moment(this.user.hireDate).format(
                        "YYYY-MM-DD"
                        )
                        : "";
                    this.user.identityValidity = this.user.identityValidity ? moment(this.user.identityValidity).format(
                        "YYYY-MM-DD"
                        )
                        : "";
                    //社会关系
                    this.socialRelation = res.socialRelation;
                    let sl=(3-res.socialRelation.length)<=0?0:3-res.socialRelation.length;
                    for(let i=0;i<sl;i++){
                        this.socialRelation.push({});
                    }
                    //合同信息
                    this.personnelContracts = res.personnelContracts;
                    let pl=(3-res.personnelContracts.length)<=0?0:3-res.personnelContracts.length;
                    for(let i=0;i<pl;i++){
                        this.personnelContracts.push({});
                    }
                    this.personnelContracts.map(item => {
                        item.startDate = item.startDate
                            ? moment(item.startDate).format(
                                "YYYY-MM-DD"
                            )
                            : "";
                        item.endDate = item.endDate ? moment(item.startDate).format(
                            "YYYY-MM-DD"
                        ) : "";
                    });
                    //工作经验
                    this.workExperience = res.workExperience;
                    let wl=(3-res.workExperience.length)<=0?0:3-res.workExperience.length;
                    for(let i=0;i<wl;i++){
                        this.workExperience.push({});
                    }
                    this.workExperience.map(item => {
                        item.startDate = item.startDate
                            ? moment(item.startDate).format(
                                "YYYY-MM-DD"
                            )
                            : "";
                        item.endDate = item.endDate ? moment(item.startDate).format(
                            "YYYY-MM-DD"
                        ) : "";
                    });
                    //教育背景
                    this.educationBackground = res.educationBackground;
                    let el=(3-res.educationBackground.length)<=0?0:3-res.educationBackground.length;
                    for(let i=0;i<el;i++){
                        this.educationBackground.push({});
                    }
                    this.educationBackground.map(item => {
                        item.startDate = item.startDate
                            ? moment(item.startDate).format(
                                "YYYY-MM-DD"
                            )
                            : "";
                        item.endDate = item.endDate ? moment(item.startDate).format(
                            "YYYY-MM-DD"
                        ) : "";
                    });
                    //证书信息
                    this.skillInfo = res.skillInfo;
                    let skl=(3-res.skillInfo.length)<=0?0:3-res.skillInfo.length;
                    for(let i=0;i<skl;i++){
                        this.skillInfo.push({});
                    }
                    this.skillInfo.map(item => {
                        item.expiryDate = item.expiryDate
                            ? moment(item.expiryDate).format(
                                "YYYY-MM-DD"
                            )
                            : "";
                        item.effectiveDate = item.effectiveDate ? moment(item.effectiveDate).format(
                            "YYYY-MM-DD"
                        ) : "";
                    });
                })
            },
            //人事信息
            personnelchange(value) {
                this.isPersonnel = !this.isPersonnel;
            },
            //合同信息
            contractchange(value) {
                this.isContract = !this.isContract;
            },
            //社会关系
            socialRelationchange(value) {
                this.isSocialRelation = !this.isSocialRelation;
            },
            //教育背景
            educationBackgroundchange(value) {
                this.isEducationBackground = !this.isEducationBackground;
            },
            //工作经验
            workExperiencechange(value) {
                this.isWorkExperience = !this.isWorkExperience;
            },
            //证书信息
            skillInfochange(value) {
                this.isSkillInfo = !this.isSkillInfo;
            },
            /**
             * 通过头像Id获取头像
             * @param profilePictureId 头像Id
             */
            getProfilePicture(profilePictureId) {
                if (profilePictureId) {
                    this.loading = true;
                    this._profileServiceProxy
                        .getProfilePictureById(profilePictureId)
                        .finally(() => (this.loading = false))
                        .then(result => {
                            if (result && result.profilePicture) {
                                let profilePreviewImage =
                                    "data:image/jpeg;base64," +
                                    result.profilePicture;
                                this.userImg=profilePreviewImage;
                            }
                        });
                }
            },
            /**
             * 打印
             */
            printer() {
                let printContents, popupWin;
                printContents = document.getElementById("content").innerHTML;
                popupWin = window.open(
                    "",
                    "_blank",
                    `top=0,left=0,height=${window.screen.availHeight},width=${window.screen.availWidth}`
                );
                popupWin.document.open();
                popupWin.document.write(`
      <html>
        <head>
		  <title>Print Page</title>
          <style>
          #content{
 width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.arc-img{
  width: 95%;
  height: 95%;
}
.max-height-150{
  min-height: 149px;
}
.info{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.info-row{
  width: 100%;
  height: 50px;
  display: flex;
}
.row-data{
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.row-data span{
  height: 100%;
  text-align: center;
  line-height: 50px;
  display: -webkit-box;             /*将对象转为弹性盒模型展示*/
  box-orient:vertical;
  -webkit-box-orient: vertical; /*设置弹性盒模型子元素的排列方式*/
  -moz-box-orient:vertical;
  -webkit-line-clamp: 1;            /*限制文本行数*/
  overflow: hidden;
}

//人事信息
.userInfo{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.content-header{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-weight: bold;
  font-size: 20px;
  height: 50px;
  color: white;
  background:#108EE9;
}
.userInfo-content{
  width: 100%;
  display: flex;
}
.userInfo-left{
  flex: 5;
  display: flex;
  flex-direction: column;
}
.userInfo-right{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.userInfo-row{
  width: 100%;
  height: 50px;
  display: flex;
}
//合同信息
.Contract{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.Contract-content{
  width: 100%;
  display: flex;
  flex-direction: column;
}
//社会关系
.SocialRelation{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.SocialRelation-content{
  width: 100%;
  display: flex;
  flex-direction: column;
}
//工作检验
.WorkExperience{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.WorkExperience-content{
  width: 100%;
  display: flex;
  flex-direction: column;
}
//教育背景
.EducationBackground{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.EducationBackground-content{
  width: 100%;
  display: flex;
  flex-direction: column;
}
//证书信息
.SkillInfo{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.SkillInfo-content{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.data-header{
  width: 100%;
  display: flex;
  height: 50px;
  font-weight: bold;
}
.data-row{
  width: 100%;
  display: flex;
  height: 50px;
}
.left-border{
  border-left: 1px solid #808080;
}
.right-border{
  border-right: 1px solid #808080;
}
.top-border{
  border-top: 1px solid #808080;
}
.bottom-border{
  border-bottom: 1px solid #808080;
}
.print-header{
color: #808080;
font-size: 12px;
display: block;
}

.flex-1{flex: 1}
.flex-2{flex: 2}
.flex-3{flex: 3}
.flex-4{flex: 4}
.flex-5{flex: 5}
.flex-6{flex: 6}
.max-width-90px{max-width: 80px;}
.max-width-100px{max-width: 100px;}
.max-width-120px{max-width: 120px;}
.max-width-150px{max-width: 150px;}
.max-width-200px{max-width: 200px;}
.max-width-250px{max-width: 250px;}
.max-width-300px{max-width: 300px;}
.max-width{max-width: 80px;}
.default-width{max-width: 70px}
.bg-width{max-width: 60px}
.SR-width{max-width: 180px;}
.ct-width{max-width: 60px}
.SRdefault-width{max-width: 90px}
.max-width-130{max-width: 100px;min-width: 100px;}
.max-width-260{max-width: 150px;min-width: 150px;}
          </style>
        </head>
		<body onload="window.print();window.close()">
		<div style='display: flex; '></div>
			${printContents}
		</body>
      </html>`
                );
                popupWin.document.close();
            }
        }

    }
