var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',[_c('a-row',[_c('a-col',{attrs:{"span":5}},[_c('organization-unit-tree-panel',{attrs:{"depuser-count":_vm.DepuserCount},on:{"selectedNode":_vm.selectedNodeFunc,"selectTree":_vm.selectTreefunc,"selectDepTree":_vm.selectTree}})],1),_c('a-col',{staticStyle:{"padding-left":"10px"},attrs:{"span":19}},[_c('a-tabs',{attrs:{"size":"small"},on:{"change":_vm.callback},model:{value:(_vm.ActiveKey),callback:function ($$v) {_vm.ActiveKey=$$v},expression:"ActiveKey"}},[(!_vm.isVirtual)?_c('a-tab-pane',{key:"1"},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_vm._v(" 员工 "),_c('a-badge',{attrs:{"count":_vm.UserCount,"overflowCount":1000000,"showZero":false,"number-style":{
								backgroundColor: '#1890FF',
								marginBottom: '10px',
								fontSize: '10px',
							}}})],1),_c('organization-unit-members-panel',{ref:"members",attrs:{"isNotChoices":_vm.isNotChoices,"tree-data":_vm.selectedTree,"companyId":_vm.CompanyId},on:{"GetUserCount":_vm.GetUserCount}})],1):_vm._e(),_c('a-tab-pane',{key:"3",attrs:{"forceRender":""}},[_c('span',{attrs:{"slot":"tab"},slot:"tab"},[_vm._v(" 兼职员工 "),_c('a-badge',{attrs:{"count":_vm.DepuserCount,"overflowCount":1000000,"showZero":false,"number-style":{
								backgroundColor: '#1890FF',
								marginBottom: '10px',
								fontSize: '10px',
							}}})],1),_c('user-dep-pluralism-panel',{ref:"userDep",attrs:{"isNotChoices":_vm.isNotChoices,"tree-data":_vm.selectedTree,"company-id":_vm.CompanyId},on:{"GetDepUserCount":_vm.GetDepUserCount}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }