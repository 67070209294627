
























































































































































































































































































































































































































































	import {AppConsts} from "@/abpPro/AppConsts";
    import {
        AnniversaryManagementSettingsEditDto,
        AppComponentBase, BirthdayWishManagementSettingEditDto, ClockInReminderMangementSettingEditDto,
        CommonLookupServiceProxy,
        CompanyImagesEditDto, ContractDueRemindSettingEdit, OmitCheckInRemindSettingEditDto,
        PasswordComplexitySetting,
        ProfileServiceProxy, RemoveCompanyEditDto,
        SecuritySettingsEditDto,
        TenantSettingsEditDto,
        TenantSettingsServiceProxy,
        TenantUserManagementSettingsEditDto,
        UserLockOutSettingsEditDto, VerifyOverTimeRemindEditDto, ZkDataDockingMangementSettingEditDto,
    } from '@/shared/index';
	import ImageUtils from "@/shared/utils/image-utils";
	import moment from 'moment'

	export default {
		name: "tenant-settings",
		mixins: [AppComponentBase],
		data() {
			return {
				spinning: false,
				previewVisible: false,
				//用户所有设置
				tenantSetting: new TenantSettingsEditDto(),
				// 图片验证码类型
				imgCodeTypeList: [],
				_commonLookupServiceProxy: "",
				_tenantSettingServiceProxy: "",
				//密码复杂程度
				passwordComplexitySetting: new PasswordComplexitySetting(),
				//锁定用户
				userLockOut: new UserLockOutSettingsEditDto(),
				//安全
				security: new SecuritySettingsEditDto(),
				//用户管理
				userManagement: new TenantUserManagementSettingsEditDto(),
				//公司图片
				companyImages: new CompanyImagesEditDto(),

				//上传参数相关
				//预览的图片
				previewImage: "",
				// 上传的接口地址
				uploadPictureUrl: AppConsts.remoteServiceBaseUrl + "/Profile/UploadCompanyPicture",
				maxCompanyPictureBytesValue: AppConsts.maxCompanyPictureMb,
				// 上传的图片类型
				uploadPictureType: '',
				// 上传时的请求头
				uploadHeaders: {
					// 企业文化图片
					loginLeft: {
						Authorization: "Bearer " + abp.auth.getToken(),
						type: 'loginLeft'
					},
					// 正方形Logo图片
					squareLogo: {
						Authorization: "Bearer " + abp.auth.getToken(),
						type: 'squareLogo'
					},
					// 长方形Logo图片
					oblongLogo: {
						Authorization: "Bearer " + abp.auth.getToken(),
						type: 'oblongLogo'
					},
					// // 网站登录背景图片
					// webBackground: {
					// 	Authorization: "Bearer " + abp.auth.getToken(),
					// 	type: 'webBackground'
					// },
					// // APP登录背景图片
					// appBackground: {
					// 	Authorization: "Bearer " + abp.auth.getToken(),
					// 	type: 'appBackground'
					// }
				},
				// 上传的文件列表
				fileList: {
					// 企业文化图片列表
					loginLeft: [],
					// 正方形Logo图片列表
					squareLogo: [],
					// 长方形Logo图片列表
					oblongLogo: [],
					// // 网站登录背景图片列表
					// webBackground: [],
					// // APP登录背景图片列表
					// appBackground: []
				},
				//后台拼接的图片路径
				companyImagesPath: {
					loginLeft: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/LoginLeft/${abp.session.tenantId}/`,
					squareLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/SquareLogo/${abp.session.tenantId}/`,
					oblongLogo: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/OblongLogo/${abp.session.tenantId}/`,
					// webBackground: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/WebBackground/${abp.session.tenantId}/`,
					// appBackground: `${AppConsts.remoteServiceBaseUrl}/${AppConsts.localization.defaultLocalizationSourceName}/AppBackground/${abp.session.tenantId}/`
				},
				removeImageList: [],
				BirthdayWishSetting: new BirthdayWishManagementSettingEditDto(),
				AnniversarySettings: new AnniversaryManagementSettingsEditDto(),
				ZkDataDockingSettings: new ZkDataDockingMangementSettingEditDto(),
                ClockInReminder:new ClockInReminderMangementSettingEditDto(),
                OmitCheckInRemind:new OmitCheckInRemindSettingEditDto(),
                VerifyOverTimeRemind:new VerifyOverTimeRemindEditDto(),
                ContractDueRemind:new ContractDueRemindSettingEdit,
				AnnDateTime: undefined,
				BriDateTime: undefined,
                OmitCheckInTime1:undefined,
                OmitCheckInTime2:undefined,
                VerifyOverTime1:undefined,
                VerifyOverTime2:undefined,
				viewStyle: undefined,
				viewWidth: 650,

				//工号规则
				jobNumberRule: {
					isEnableAutoJobNum: true, //是否启用自动工号
					fixedPrefix: '', //前缀
					isEnableCompanyCode: false, //是否启用公司编码
					serialNumber: 5 //序号位数
				},
                //工号规则的提交参数
				fixedRule: [{
					"itemType": "0",
					"formatStr": "是否启用自动工号(忽略)",
					"stepValue": "",
					"initValue": "",
					"description": "true",
					"itemTypeName": "自定义"
				}, {
					"itemType": "0",
					"formatStr": "",
					"stepValue": "",
					"initValue": "",
					"description": "",
					"itemTypeName": "自定义"
				}, {
					"itemType": "3",
					"formatStr": "code",
					"stepValue": "",
					"initValue": "",
					"description": "false",
					"itemTypeName": "公司"
				}, {
					"itemType": "2",
					"formatStr": "000000",
					"stepValue": "",
					"initValue": "",
					"description": "",
					"itemTypeName": "流水号"
				}],
			}
		},
		created() {
			this._profileServiceProxy = new ProfileServiceProxy(this.$apiUrl, this.$api);
			this._commonLookupServiceProxy = new CommonLookupServiceProxy(this.$apiUrl, this.$api);
			this._tenantSettingServiceProxy = new TenantSettingsServiceProxy(this.$apiUrl, this.$api);
			this.getData();
			this.getimgCodeTypeList();
		},
		methods: {
			getData() {
				this.spinning = true;
				this._tenantSettingServiceProxy.getAllSettings()
					.finally(() => {
						this.spinning = false;
					})
					.then((res: TenantSettingsEditDto) => {
                        this.VerifyOverTimeRemind=res.verifyOverTimeRemindMangement;
                        this.OmitCheckInRemind=res.omitCheckInRemindMangement;
                        this.AnniversarySettings = res.anniversaryManagement;
                        this.BirthdayWishSetting = res.birthdayWishManagement;
						if (!res.anniversaryManagement.hour) {
							this.AnnDateTime = moment("08:00", 'HH:mm');
							this.AnniversarySettings.hour = 8;
							this.AnniversarySettings.minute = 0;
						} else {
							let s = res.anniversaryManagement.hour + ":" + res.anniversaryManagement.minute;
							this.AnnDateTime = moment(s, 'HH:mm');
						}

						if (!res.birthdayWishManagement.hour) {
							this.BriDateTime = moment("08:00", 'HH:mm');
							this.BirthdayWishSetting.hour = 8;
							this.BirthdayWishSetting.minute = 0;
						} else {
							let s = res.birthdayWishManagement.hour + ":" + res.birthdayWishManagement.minute;
							this.BriDateTime = moment(s, 'HH:mm');
						}

                        if (!res.omitCheckInRemindMangement.hour1) {
                            this.OmitCheckInTime1 = moment("09:00", 'HH:mm');
                            this.OmitCheckInRemind.hour1 = 9;
                            this.OmitCheckInRemind.minute1 = 0;

                        } else {
                            let s = res.omitCheckInRemindMangement.hour1 + ":" + res.omitCheckInRemindMangement.minute1;
                            this.OmitCheckInTime1 = moment(s, 'HH:mm');

                        }
                        if (!res.omitCheckInRemindMangement.hour2) {
                            this.OmitCheckInTime2 = moment("14:00", 'HH:mm');
                            this.OmitCheckInRemind.hour2 = 14;
                            this.OmitCheckInRemind.minute2 = 0;
                        } else {
                            let s = res.omitCheckInRemindMangement.hour2 + ":" + res.omitCheckInRemindMangement.minute2;
                            this.OmitCheckInTime2 = moment(s, 'HH:mm');
                        }

                        if (!res.verifyOverTimeRemindMangement.hour1) {
                            this.VerifyOverTime1 = moment("08:00", 'HH:mm');
                            this.VerifyOverTimeRemind.hour1 = 8;
                            this.VerifyOverTimeRemind.minute1 = 0;

                        } else {
                            let s = res.verifyOverTimeRemindMangement.hour1 + ":" + res.verifyOverTimeRemindMangement.minute1;
                            this.VerifyOverTime1 = moment(s, 'HH:mm');

                        }

                        if (!res.verifyOverTimeRemindMangement.hour2) {
                            this.VerifyOverTime2 = moment("13:00", 'HH:mm');
                            this.VerifyOverTimeRemind.hour2 = 13;
                            this.VerifyOverTimeRemind.minute2 = 0;
                        } else {
                            let s = res.verifyOverTimeRemindMangement.hour2 + ":" + res.verifyOverTimeRemindMangement.minute2;
                            this.VerifyOverTime2 = moment(s, 'HH:mm');
                        }

						this.ZkDataDockingSettings = res.zkDataDockingManagement;
						if (!this.ZkDataDockingSettings.minute) {
							this.ZkDataDockingSettings.minute = 5;
						}
						this.ClockInReminder=res.clockInReminderMangement;
						if(!this.ClockInReminder.minute){
						    this.ClockInReminder.minute=5;
                        }
						this.ContractDueRemind=res.contractDueRemind;
						//this.tenantSetting = res;
						this.userManagement = res.userManagement;
						this.security = res.security;
						this.passwordComplexitySetting = res.security.passwordComplexity;
						this.userLockOut = res.security.userLockOut;
						this.companyImages = res.companyImages;
						if (this.companyImages.loginLeft) {
							//http://localhost:6298/QYB/LoginLeft/9/e9b67980-9253-41b3-a685-a8c0724bbf3b.jpg
							this.fileList.loginLeft = [{
								uid: -1,
								name: this.companyImages.loginLeft,
								status: "done",
								url: this.companyImagesPath.loginLeft + this.companyImages.loginLeft,
								response: {
									result: {
										fileName: this.companyImages.loginLeft
									}
								}
							}];
						}
						if (this.companyImages.squareLogo) {
							this.fileList.squareLogo = [{
								uid: -1,
								name: this.companyImages.squareLogo,
								status: "done",
								url: this.companyImagesPath.squareLogo + this.companyImages.squareLogo,
								response: {
									result: {
										fileName: this.companyImages.squareLogo
									}
								}
							}];
						}
						if (this.companyImages.oblongLogo) {
							this.fileList.oblongLogo = [{
								uid: -1,
								name: this.companyImages.oblongLogo,
								status: "done",
								url: this.companyImagesPath.oblongLogo + this.companyImages.oblongLogo,
								response: {
									result: {
										fileName: this.companyImages.oblongLogo
									}
								}
							}];
						}

						//赋值工号规则
						if(res.jobNumberRule){
							const jobNumberRule = JSON.parse(res.jobNumberRule);
							this.jobNumberRule.isEnableAutoJobNum = jobNumberRule[0].description !== 'false';
							this.jobNumberRule.fixedPrefix = jobNumberRule[1].formatStr;
							this.jobNumberRule.isEnableCompanyCode = jobNumberRule[2].description !== 'false';
							this.jobNumberRule.serialNumber = jobNumberRule[3].formatStr.length;
                        }
					})
			},
			handleSubmit(e) {
				try {
					if (!this.security.useDefaultPasswordComplexitySettings) {
						this.security.passwordComplexity = this.passwordComplexitySetting;
					}
					this.security.userLockOut = this.userLockOut;

					this.tenantSetting.userManagement = this.userManagement;
					this.tenantSetting.security = this.security;
					this.tenantSetting.companyImages = this.companyImages;
					this.tenantSetting.removeCompanyImageList = this.removeImageList;
					this.tenantSetting.anniversaryManagement = this.AnniversarySettings;
					this.tenantSetting.birthdayWishManagement = this.BirthdayWishSetting;
					this.tenantSetting.zkDataDockingManagement = this.ZkDataDockingSettings;
                    this.tenantSetting.clockInReminderMangement=this.ClockInReminder;
                    this.tenantSetting.omitCheckInRemindMangement=this.OmitCheckInRemind;
                    this.tenantSetting.verifyOverTimeRemindMangement=this.VerifyOverTimeRemind;
                    this.tenantSetting.contractDueRemind=this.ContractDueRemind;
					try{
						//赋值工号规则
						//是否启用自动工号
						this.fixedRule[0].description = this.jobNumberRule.isEnableAutoJobNum.toString();
						//固定前缀
						this.fixedRule[1].formatStr = this.jobNumberRule.fixedPrefix;
						//是否启用公司编码
						this.fixedRule[2].description = this.jobNumberRule.isEnableCompanyCode.toString();
						//序号位数
						this.fixedRule[3].formatStr = new Array(this.jobNumberRule.serialNumber).fill(0).toString().replace(RegExp(",", "g"), '');
						//序列化
						this.tenantSetting.jobNumberRule = JSON.stringify(this.fixedRule);
                    }catch (e) {
                        abp.message.error('工号规则保存异常'+JSON.stringify(e));
					}

					this.spinning = true;
					this._tenantSettingServiceProxy
						.updateAllSettings(this.tenantSetting)
						.finally(() => {
							this.spinning = false;
						})
						.then(res => {
							this.$notification.success({
								description: undefined,
								message: this.l("SavedSuccessfully")
							})
						})
				} catch (e) {
					this.spinning = false;
				}

			},
			/**
			 * 获取图片验证嘛类型
			 */
			getimgCodeTypeList() {
				this._commonLookupServiceProxy
					.getValidateCodeTypesForCombobox()
					.finally(() => {
					})
					.then(res => {
						this.imgCodeTypeList = res.items;
					});
			},
			/**
			 * 用户登录图片验证码类型
			 */
			CaptchaOnTenantRegistrationTypeChange(value) {
				this.userManagement.captchaOnUserLoginType = value;
			},
			//上传相关方法
			/**
			 * 上传图片
			 */
			beforeUpload(file) {
				const isJPG =
					file.type === "image/jpeg" ||
					file.type === "image/png" ||
					file.type === "image/gif";
				if (!isJPG) {
					abp.message.error(this.l("OnlySupportPictureFile"));
				}
				const isLtXM = file.size / 1024 / 1024 < this.maxCompanyPictureBytesValue;
				if (!isLtXM) {
					abp.message.error(
						this.l(
							"ProfilePicture_Warn_SizeLimit",
							this.maxCompanyPictureBytesValue
						)
					);
				}
				return isJPG && isLtXM;
			},
			/**
			 * 预览图片
			 * @param file 文件
			 * @returns {Promise<void>}
			 */
			async handlePreview(file, type) {
				this.viewStyle = undefined;
				this.viewWidth = undefined;
				if (!file.url && !file.preview) {
					file.preview = await ImageUtils.getBase64(file.originFileObj);
				}
				this.previewImage = file.url || file.preview;
				this.previewVisible = true;
				switch (type) {
					//企业文化
					case "loginLeft":
						this.viewWidth = 650;
						this.viewStyle = {width: "650px", height: "500px"};
						break;
					//     //正方形Logo
					case "squareLogo":
						this.viewWidth = 400;
						this.viewStyle = {width: "400px", height: "400px"};
						break;

					case "oblongLogo":
						this.viewWidth = 400;
						this.viewStyle = {width: "400px", height: "100px"};
						break;
					default :
						this.viewWidth = 520;
						// this.viewStyle={width:"200px",height:"50px"};
						break;
				}
				// if (!file.url && !file.preview) {
				// 	file.preview = await ImageUtils.getBase64(file.originFileObj);
				// }
				// this.previewImage = file.url || file.preview;
				// ImageUtils.showFullImage(this.previewImage);
			},
			/**
			 * 上传图片回调  状态 "status: "done"  status: "uploading"  status: "removed"
			 */
			uploadPictureChange({file, fileList}, uploadPictureType) {
				switch (uploadPictureType) {
					case 'loginLeft':
						if (file.status === 'done') {
							this.companyImages.loginLeft = file.response.result.fileName;
						} else if (file.status === 'removed') {
							this.companyImages.loginLeft = undefined;
							//添加移除文件
							let dto = new RemoveCompanyEditDto();
							dto.type = 3;
							dto.fileName = file.response.result.fileName;
							this.removeImageList.push(dto);
						}
						this.fileList.loginLeft = fileList;
						break;
					case 'squareLogo':
						if (file.status === 'done') {
							this.companyImages.squareLogo = file.response.result.fileName;
						} else if (file.status === 'removed') {
							this.companyImages.squareLogo = undefined;
							//添加移除文件
							let dto = new RemoveCompanyEditDto();
							dto.type = 1;
							dto.fileName = file.response.result.fileName;
							this.removeImageList.push(dto);
						}
						this.fileList.squareLogo = fileList;
						break;
					case 'oblongLogo':
						if (file.status === 'done') {
							this.companyImages.oblongLogo = file.response.result.fileName;
						} else if (file.status === 'removed') {
							this.companyImages.oblongLogo = undefined;
							this.companyImages.squareLogo = undefined;
							//添加移除文件
							let dto = new RemoveCompanyEditDto();
							dto.type = 2;
							dto.fileName = file.response.result.fileName;
							this.removeImageList.push(dto);
						}
						this.fileList.oblongLogo = fileList;
						break;
					// case 'webBackground':
					// 	if(file.status === 'done'){
					// 		this.companyImages.webBackground = file.response.result.fileName;
					// 	}else if(file.status === 'removed'){
					// 		this.companyImages.webBackground = undefined;
					// 	}
					// 	this.fileList.webBackground = fileList;
					// 	break;
					// case 'appBackground':
					// 	if(file.status === 'done'){
					// 		this.companyImages.appBackground = file.response.result.fileName;
					// 	}else if(file.status === 'removed'){
					// 		this.companyImages.appBackground = undefined;
					// 	}
					// 	this.fileList.appBackground = fileList;
					// 	break;
				}
			},
			/**
			 * 入职周年时间
			 * @constructor
			 */
			AnnDateChange(record, str) {
				if (str) {
					let s = str.split(':');
					this.AnniversarySettings.hour = parseInt(s[0]);
					this.AnniversarySettings.minute = parseInt(s[1]);
				}
			},
			/**
			 * 生日祝福时间
			 * @constructor
			 */
			BirDateChange(record, str) {
				if (str) {
					let s = str.split(':');
					this.BirthdayWishSetting.hour = parseInt(s[0]);
					this.BirthdayWishSetting.minute = parseInt(s[1]);
				}
			},
            /**
             * 漏打卡时间
             * */
            OmitCheckInTimeChange1(record,str){
			    if (str){
			        let s=str.split(':');
			        this.OmitCheckInRemind.hour1=parseInt(s[0]);
			        this.OmitCheckInRemind.minute1=parseInt(s[1]);
                }
            },
            OmitCheckInTimeChange2(record,str){
                if (str){
                    let s=str.split(':');
                    this.OmitCheckInRemind.hour2=parseInt(s[0]);
                    this.OmitCheckInRemind.minute2=parseInt(s[1]);
                }
            },
            /**
             * 审核超时提醒时间
             * */
            VerifyOverTimeChange1(record,str){
                if (str){
                    let s=str.split(':');
                    this.VerifyOverTimeRemind.hour1=parseInt(s[0]);
                    this.VerifyOverTimeRemind.minute1=parseInt(s[1]);
                }
            },
            VerifyOverTimeChange2(record,str){
                if (str){
                    let s=str.split(':');
                    this.VerifyOverTimeRemind.hour2=parseInt(s[0]);
                    this.VerifyOverTimeRemind.minute2=parseInt(s[1]);
                }
            }
		}
	}
